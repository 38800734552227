import React, { useEffect } from 'react'
import 'swagger-ui/dist/swagger-ui.css'

import {useAccessToken} from 'hooks'


const ApiDocs = () => {
  const acquireAccessToken = useAccessToken();
  useEffect(() => {
    const url = process.env.REACT_APP_API_BASE + '/docs'
    let swaggerUi
    import('swagger-ui').then(({default: SwaggerUI}) => {
      swaggerUi = SwaggerUI({
        dom_id: '#api-docs',
        url,
        requestInterceptor: async (req) => {
            const accessToken  = await acquireAccessToken()
            req.headers.Authorization = `Bearer ${accessToken}`
            req.headers['X-Authorization'] = `Bearer ${accessToken}`
            req.headers['Ocp-Apim-Subscription-Key'] = process.env.REACT_APP_HEADER_SUBSCRIPTION
          return req;
        },
        deepLinking: true,
        sorter: "alpha",
        jsonEditor: true,
        defaultModelRendering: 'schema',
        showRequestHeaders: true,
        presets: [
          SwaggerUI.presets.apis,
          SwaggerUI.presets.base,
          SwaggerUI.presets.lowlight
        ],
        plugins: [
          SwaggerUI.plugins.DownloadUrl
        ],
        onComplete: async () => {
          const accessToken  = await acquireAccessToken()
          swaggerUi.preauthorizeApiKey('ApiKey',  `Bearer ${accessToken}` )
        }
      })
    })
  }, [])

  return (
    <div id='api-docs' style={{background: 'white'}} />
  )
}

export default ApiDocs