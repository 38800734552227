import useQueryString from 'hooks/useQueryString'

const usePagination = (namespace) => {
  const [urlState, setUrlState] = useQueryString(namespace, ['page'])

  const page = Number(urlState.page) || 1
  const setPage = (newPage) => {
    setUrlState({ page: newPage <= 1 ? undefined : newPage })
  }

  return [page, setPage]
}
export default usePagination
