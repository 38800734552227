import 'react-app-polyfill/ie11';
import './polyfills'
import './index.css';
import './DevTools';
import React from 'react';
import ReactDOM from 'react-dom';
import AppContainer from './containers/AppContainer';

import { PublicClientApplication } from "@azure/msal-browser";
import {  MicrosoftService } from 'services'

// @ts-ignore
export const msalInstance = new PublicClientApplication(MicrosoftService.config());


ReactDOM.render(<AppContainer instance={msalInstance} />, document.getElementById('root'));

