import React, { useContext } from 'react'
import { SnackbarContext } from 'contexts'
import Button from '@material-ui/core/Button'
import MuiSnackbar from '@material-ui/core/Snackbar'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'

const Snackbar = () => {
  const snackbar = useContext(SnackbarContext.ReactContext)

  return (
    <MuiSnackbar
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      open={!!snackbar.message}
      autoHideDuration={3000}
      onClose={snackbar.actions.clear}
      message={<span id="message-id">{snackbar.message}</span>}
      action={[
        <Button key="undo" color="secondary" size="small" onClick={snackbar.actions.clear}>
          CLOSE
        </Button>,
        <IconButton
          key="close"
          aria-label="Close"
          color="inherit"
          onClick={snackbar.actions.clear}
        >
          <CloseIcon />
        </IconButton>,
      ]}
    />
  )
}

export default (props) => (
  <Snackbar {...props} />
)
