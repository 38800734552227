import APIContext from 'contexts/base/APIContext'

export default class LogRecordsContext extends APIContext{
  static contextName = 'logRecords'

  static initialState = {
    ...super.initialState,
    message: '',
  }

  create = async(item, {...options}={}) => {
    const { data:selected } = await this.performRequest('create')({...item, options})
    return selected
  }

  supersede = async(item, options={}) => {
    const { data: selected } = await this.performRequest('supersede')({...item, options})
    return selected
  }

  download = async ({options}) => {
    const { data } = await this.performRequest('download')({options})
    return data.blob
  }

  clear = async () => {
    this.setState({list: [], totalPages: 0, count: 0})
  }

  clearErrors = async () => {
    this.setState({errors: {}})
  }

}
