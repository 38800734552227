import List from "@material-ui/core/List";
import Button from "@material-ui/core/Button";
import React from "react";
import {makeStyles} from "@material-ui/core/styles";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import Checkbox from "@material-ui/core/Checkbox";
import ListItemText from "@material-ui/core/ListItemText";
import Typography from "@material-ui/core/Typography";
import {Box} from "@material-ui/core";


const CheckList = ({ items, onToggle, onSave, onReset }) => {
  return (
    <Box>
      <List dense>
        {items?.map(item => <CheckListItem item={item} onToggle={onToggle} />)}
      </List>
      {items?.length > 0
        ?
          <>
            {onSave && <Button color='secondary' variant='contained' onClick={onSave}>Save</Button>}
            {onReset && <Button color='primary' variant='contained' onClick={onReset}>Reset</Button>}
          </>
        :
        <Typography>No items to assign</Typography>
      }
    </Box>
  )
}

const CheckListItem = ({ item, onToggle }) => {
  const { id, title, checked } = item
  const classes = useStyles()

  return (
    <ListItem button dense classes={{ dense: classes.dense }} key={id} onClick={() => onToggle(item)}>
      <ListItemIcon>
        <Checkbox
          edge="start"
          checked={Boolean(checked)}
          tabIndex={-1}
          disableRipple
        />
      </ListItemIcon>
      <ListItemText primary={title}/>
    </ListItem>
  )
}


const useStyles = makeStyles({
  dense: {
    paddingTop: 0,
    paddingBottom: 0,
    '& .MuiButtonBase-root': {
      padding: 4,
    },
    '& .MuiListItemIcon-root': {
      minWidth: 26
    }
  },
});

export default CheckList