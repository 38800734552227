import React from 'react'
import { Switch } from 'react-router-dom'

const Routes = () => {
  return (
    <Switch>
    </Switch>
  )
}

export default Routes