import APIContext from 'contexts/base/APIContext'

export default class LogBooksUsersContext extends APIContext{
  static contextName = 'logBooksUsers'


  set = async(item, {...options}={}) => {
    const { data } = await this.performRequest('set')({...item, options})
    this.setState({list: data})
    return data
  }
}
