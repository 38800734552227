import { LogLevel } from "@azure/msal-browser";

class MicrosoftService {

  config = () => {
    return {
      auth: {
          clientId: process.env.REACT_APP_MSAL_CLIENT_ID,
          authority: process.env.REACT_APP_MSAL_TENANT_ID,
          redirectUri: "/",
          postLogoutRedirectUri: "/",
          navigateToLoginRequestUrl: false,
      },

      cache: {
          cacheLocation: "localStorage", // Configures cache location. "sessionStorage" is more secure, but "localStorage" gives you SSO between tabs.
          storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
      },
      system: {
          loggerOptions: {
              loggerCallback: (level, message, containsPii) => {
                  if (containsPii) {
                      return;
                  }
                  switch (level) {
                      case LogLevel.Error:
                          console.error(message);
                          return;
                      case LogLevel.Info:
                          console.info(message);
                          return;
                      case LogLevel.Verbose:
                          console.debug(message);
                          return;
                      case LogLevel.Warning:
                          console.warn(message);
                          return;
                      default:
                          console.warn(message);
                          return
                  }
              }
          }
      }
    }
  }

  login = () => {
    return {
      scopes: [process.env.REACT_APP_MSAL_SCOPE]
    }
  }
}

const MicrosoftServiceInstance = new MicrosoftService()
export { MicrosoftServiceInstance as MicrosoftService }
