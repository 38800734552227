import React, {useContext, useEffect, useState} from 'react'
import deepEqual from 'fast-deep-equal'
import { Pagination, ErrorBanner, PageContainer } from 'components'
import { errorStringsFromError, userFriendlyDate, endOfToday, startOfToday, Authorization, timeout } from 'utils'
import { LogRecordsContext, LogBooksContext } from 'contexts'
import { usePagination, useDependency, useFilter } from 'hooks'
import FormDialogButton from "./FormDialogButton";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableContainer from '@material-ui/core/TableContainer';
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import {Box, makeStyles, useMediaQuery} from "@material-ui/core";
import {AutocompleteSearch, ControlledForm, LabeledCheckbox, DateTimePicker, LoaderOverlay, LabeledSelect} from "components"
import Button from "@material-ui/core/Button";
import TextField from '@material-ui/core/TextField'
import MenuItem from '@material-ui/core/MenuItem'
import {useAccessToken} from "hooks";
import moment from 'moment'
import AutocompleteReferences from "./AutocompleteReferences";
import AutocompleteInstructions from "./AutocompleteInstructions";
import AutocompleteUsers from "./AutocompleteUsers";
import Paper from "@material-ui/core/Paper";


const useStyles = makeStyles({
  defaultRow: {
    '& .MuiTableCell-root': {
      color: '#2a36e2',
      border: "1px solid rgba(224, 224, 224, 1)"
    },
  },
  selectableRow: {
    cursor: 'pointer',
  },
  supersededRow: {
    '& .MuiTableCell-root': {
      textDecorationLine: 'line-through'
    }
  },
  editableRow: {
    '& .MuiTableCell-root': {
      color: 'Black',
    }
  },
  logInput: {
    display: 'inlineFlex'
  },
})

const LOGS_REFRESH_TIMER = {
  timer: null
}

const Logs = () => {
  const logRecords = useContext(LogRecordsContext.ReactContext)
  const logBooks   = useContext(LogBooksContext.ReactContext)

  const [page, setPage] = usePagination('logRecords')
  const [filter, setFilter] = useFilter(setPage, 'logRecords')

  const REFRESH_INTERVAL = 30 * 1000

  useEffect(() => {
    window.clearInterval(LOGS_REFRESH_TIMER.timer)
    LOGS_REFRESH_TIMER.timer = window.setInterval(reloadLogRecords, REFRESH_INTERVAL)
    return () => {
        window.clearInterval(LOGS_REFRESH_TIMER.timer)
        LOGS_REFRESH_TIMER.timer = null
    }
  }, [page, filter]);

  useEffect(() => {
    if (Object.keys(filter).length === 0) {
      setFilter(defaultFilters())
    }
  }, [])

  const defaultFilters = (logBook) => {
    return logBook ? {logBook} : {}
  }

  const [loadingLogBooks,,] = useDependency(() => {
    return logBooks.actions.index({
      page: { number: 1, size: 100 },
      include: 'user',
      fields: {logBooks: 'title,valid_for_user'},
      filter: {activeFlag: "Y"},
      sort: 'title'
     })
   }, [])

  const handleLogBookFilterChanged = ({target: { value }}) => {
    const logBook = logBooks.list.find(logBook => logBook.id == value)
    setFilter({...filter, logBook: logBook})
  }

  const selectedLogBook = filter.logBook
  const [loading, , reloadLogRecords] = useDependency(() => {
    if (!filter.logBook) {
      // logbook filter is required
      logRecords.actions.clear()
      return
    }
    return logRecords.actions.index({
      page: page,
      include: 'user,logReference,logInstruction,supersededBy',
      filter,
      sort: '-eventDatetime, -id'
     })
   }, [page, filter])

  const handleFindRecord = (record) => () => {
    const filters = {...defaultFilters(selectedLogBook), id: record.supersededBy.id}
    setFilter(filters)
  }

  const [selected, setSelected] = useState()
  const handleSelect = (record) => () => {
    if (!editable(record) && record.id !== selected?.id) {
      return;
    }
    setSelected(prevState => prevState?.id === record.id ? undefined : record)
  }

  const handleResetFilters = () => {
    setFilter({...clearFilters(filter), ...defaultFilters(selectedLogBook)})
  }

  const clearFilters = (filter) => Object.fromEntries(Object.entries(filter).map(([k, v]) => [k, undefined]))

  const editable = (record) => {
    return (record.user?.id === Authorization.userId) && !record.supersededBy
  }

  const classes = useStyles()
  const records = fixSelfReferentialJsonApiRecords(logRecords);

  const isSupersede = (record) => moment(record.editExpiresDatetime) < moment()

  const hasDisabledActions = selectedLogBook === undefined ? true : false
  const logBookTitle = selectedLogBook === undefined ? 'Log ' : `Log records for ${selectedLogBook.title}`

  const rowClasses = (record) => {
    const classesList = []
    classesList.push(classes.defaultRow)
    if (editable(record)) {
      classesList.push(classes.selectableRow)
      if(!isSupersede(record)) classesList.push(classes.editableRow)
    }
    if (record.supersededBy) {
      classesList.push(classes.supersededRow)
    }

    return classesList.join(" ")
  }

  const [hidden, setHidden] = useState(true)

  const smallScreen = useMediaQuery('(max-width:1099px)')

  const handleExport = () => async (event) => {
    event.preventDefault()
    event.stopPropagation()
    var blob = await logRecords.actions.download({
      options: {
        include: 'user,logReference,logInstruction,supersededBy',
        filter: filter,
        sort: '-eventDatetime, -id',
      }
    })

    window.open( URL.createObjectURL(blob) )
    setTimeout(() => {
      URL.revokeObjectURL(blob)
    },5000)
  }

  return (
    <PageContainer>
      {(loading || loadingLogBooks) && <LoaderOverlay /> }
      <ControlledForm data={filter} onChange={setFilter}>
      <Box display="grid" gridTemplateColumns={"1fr 1fr"} alignItems="end" gridColumnGap="10px">
          <LabeledSelect fullWidth label='Log Book'
                                    member='filter.logBook'
                                    onChange={handleLogBookFilterChanged}
                                    value={selectedLogBook ? selectedLogBook.id : undefined}>
            <MenuItem value={undefined}></MenuItem>
            {logBooks.list.filter(lb => lb.validForUser).map(logbook =>
                <MenuItem key={logbook.id} value={logbook.id}>{logbook.title}</MenuItem>
            )}

          </LabeledSelect>

        {Authorization.canWrite &&
          <Box display="flex" justifyContent="end">
            <FormDialogButton disabled={hasDisabledActions} logBook={selectedLogBook} onSave={reloadLogRecords} />
            {selected &&
              <FormDialogButton disabled={hasDisabledActions} logBook={selectedLogBook} logRecord={records.find(r => r.id === selected.id)} onSave={reloadLogRecords} edit />
            }
            <Button color="primary" variant="contained" onClick={handleExport()} disabled={hasDisabledActions}>Export as CSV</Button>
          </Box>
        }
      </Box>

      <ErrorBanner>
        {errorStringsFromError(logRecords.actions.apiErrorsFor('index', 'destroy'))}
      </ErrorBanner>

      <Box>
        {hidden &&
          <Button variant="outlined" color="primary" size="small" onClick={() => setHidden(!hidden)}>
            Filters
          </Button>
        }
        {!hidden &&
            <Box display="grid" gridTemplateColumns={smallScreen ? "1fr 1fr" : "1fr 1fr 1fr"} alignItems="end" gridColumnGap="10px">
              <DateTimePicker name="eventFrom" fullWidth initialFocusedDate={startOfToday()} label="Actual Date From" />
              <DateTimePicker name="eventTo" fullWidth initialFocusedDate={endOfToday()} label="Actual Date To" />
              <AutocompleteReferences logBook={selectedLogBook} name="logReference" />
              <DateTimePicker name="createdFrom" fullWidth initialFocusedDate={startOfToday()} label="Created Date From" />
              <DateTimePicker name="createdTo" fullWidth initialFocusedDate={endOfToday()} label="Created Date To" />
              <AutocompleteInstructions logBook={selectedLogBook} name="logInstruction" />
              <AutocompleteUsers logBook={selectedLogBook} name="user" />

              <TextField name="comments" />
              <TextField name="prefix" />
                <Box />
                <Box />
                <Box display="flex" justifyContent="end">
                  <Button variant="outlined" color="secondary" onClick={handleResetFilters} disabled={deepEqual(filter, defaultFilters(selectedLogBook))}>Reset</Button>
                  <Button variant="outlined" color="primary" onClick={() => setHidden(true)}>Hide</Button>
                </Box>
            </Box>
        }
      </Box>
      </ControlledForm>

      <Pagination totalPages={logRecords.totalPages} page={page} onPageSelected={setPage}/>
      <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>User</TableCell>
            <TableCell>Event Date</TableCell>
            <TableCell>Reference</TableCell>
            <TableCell>Prefix</TableCell>
            <TableCell>Instruction</TableCell>
            <TableCell>Comment</TableCell>
            <TableCell>Created Date</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {records.map(record => (
            <TableRow key={record.id}
                      onClick={handleSelect(record)}
                      selected={selected?.id === record.id}
                      className={rowClasses(record)}>
              <TableCell>{record.user.name}</TableCell>
              <TableCell>{userFriendlyDate(record.eventDatetime)}</TableCell>
              <TableCell>{record.logReference?.name}</TableCell>
              <TableCell>{record.prefix}</TableCell>
              <TableCell>{record.logInstruction?.name}</TableCell>
              <TableCell>{record.comments}</TableCell>
              <TableCell>{userFriendlyDate(record.createdDatetime)}</TableCell>
              </TableRow>
          ))}
        </TableBody>
      </Table>
      </TableContainer>
      <Pagination totalPages={logRecords.totalPages} page={page} onPageSelected={setPage}/>
    </PageContainer>
  )
}

// This hack fixes a bug in our JSONApi parser.
// Our parser is only linking relationships if they were in the `included` part of JSONApi.
// However, if the record we are linking was present in a sibling record, we won't link it.
function fixSelfReferentialJsonApiRecords(logRecords) {
  const recordsMap = Object.fromEntries(logRecords.list.map(r => [r.id, r]))
  return logRecords.list.map(({supersededById, ...record}) => {
    if (supersededById && !record.supersededBy) {
      return {...record, supersededBy: recordsMap[supersededById]}
    }
    return record
  })
}

export default (props) => (
  <LogBooksContext>
    <LogRecordsContext>
      <Logs {...props} />
    </LogRecordsContext>
  </LogBooksContext>
)