import React, { Component } from 'react'
import MUIAutocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import { debounce } from 'utils'
import parse from 'autosuggest-highlight/parse';
import match from 'autosuggest-highlight/match';

export class Autocomplete extends Component{

  state = {
    options: [],
    loading: true,
    open: false
  }

  handleKeyUp = (event) => {
    if((event.which >= 65 && event.which <= 122) || event.which === 8 || event.which === 46){
      this.requestFetch(event.target.value)
    }
  }

  handleReceiveOptions = (options) => {
    if(this.state.options !== options){
      this.setState({loading: false})
      this.setState({options})
    }
  }

  requestFetch = debounce((text) => {
    if(!this.props.onSuggestionsFetchRequested){
      if(!this.props.options){
        console.warn("No options given to Autocomplete. Please provide one of the following props: onSuggestionsFetchRequested(text, callback) or options[array]")
      }
    }
    else{
      text = typeof text === 'string' ? text : this.getOptionLabel(this.props.value)
      this.setState({loading: true})
      this.props.onSuggestionsFetchRequested(text, this.handleReceiveOptions)
    }
  })

  handleChange = (event, value) => {
    this.props.onChange({ target: { value }})
  }

  get getOptionLabel(){
    return this.props.getOptionLabel || (option => typeof option === 'object' ? option.name : option)
  }

  render = () => {
    const { onSuggestionsFetchRequested, getOptionLabel, onChange, label, options, variant, fullWidth, ...acProps } = this.props
    return (
      <MUIAutocomplete
        {...acProps}
        onOpen={this.requestFetch}
        onChange={this.handleChange}
        options={options || this.state.options}
        loading={this.state.loading}
        getOptionLabel={this.getOptionLabel}
        renderInput={params => (
          <TextField {...params}
            onKeyUp={this.props.onSuggestionsFetchRequested ? this.handleKeyUp : null}
            fullWidth={fullWidth}
            label={label}
            variant={variant}
          />
        )}
        renderOption={(option, { inputValue }) => {
          const label = this.getOptionLabel(option)
          const matches = match(label, inputValue);
          const parts = parse(label, matches);

          return (
            <div>
              {parts.map((part, index) => (
                <span key={index} style={{ fontWeight: part.highlight ? 700 : 400 }}>
                  {part.text}
                </span>
              ))}
            </div>
          );
        }}
      />
    )
  }

}

export default Autocomplete