import { useState, useEffect } from 'react'
import { history }             from 'utils';

const redirect = (path) => {
  history.replace(path)
}

const handleDependenciesFailed = (err) => {
  const error = err.length ? err[0] : err
  switch (error.status) {
    case 403: break
    case 408: {
      redirect('/timed_out')
      break
    }
    case 504: {
      redirect('/timed_out')
      break
    }
    case 404: {
      redirect('/not_found')
      break
    }
    case undefined:
    case null: {
      break
    }
    default: {
      break
    }
  }
}

const useDependency = (resolver, deps = []) => {
  const [loading, setLoading] = useState(true)
  const [result, setResult] = useState(false)

  const resolve = async (...opts) => {
    setLoading(true)
    setResult(null)
    try {
      const newResult = await resolver(...opts)
      setResult(newResult)
    } catch (error) {
      handleDependenciesFailed(error)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    resolve()
    // eslint-disable-next-line
  }, deps)

  return [loading, result, resolve]
}

export default useDependency
