import { useRouteMatch } from 'react-router-dom'

const useResourceFromPath = () => {
  const match = useRouteMatch()

  const type = match.path.split('/')[1]
  const id = match.params.id
  const action = match.path.match(/\/new(?:\/:[^/]+)?$/) ? 'new' : 'edit'

  return {...type && {type}, ...id && {id}, ...action && {action}}
}

export default useResourceFromPath
