import React from "react";
import {Box, Typography} from "@material-ui/core";

const PageHeader = ({title, actions}) => {
  return (
    <Box display="flex" justifyContent="space-between" mb={1}>
      <Typography variant="h4">{title}</Typography>
      <Box>
        {actions}
      </Box>
    </Box>
  )
}

export default PageHeader