import React from 'react'
import StatusPage from './StatusPage'
import {useHistory} from 'react-router-dom'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import { Link } from 'react-router-dom'

const Unauthorized = () => {
  const history = useHistory()
  return (
    <StatusPage>
      <Typography variant='h5'>Uh-ohh</Typography>
      <Typography variant='body1' >You're not authorised to do this.</Typography>
      Check <Link to='/profile' style={{color:"#00adb5"}}>your profile</Link> to check your assigned roles.
      <br/>
      <Button fullWidth variant='contained' color='secondary' onClick={history.goBack}>Go Back!</Button>
    </StatusPage>
  )
}

export default Unauthorized