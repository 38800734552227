import React, {useContext} from 'react'
import {Pagination, ErrorBanner, PageContainer, DeleteConfirmationDialog, SearchCombo, PageHeader, LoaderOverlay} from 'components'
import AddIcon from '@material-ui/icons/Add'
import Button from "@material-ui/core/Button";
import IconButton from '@material-ui/core/IconButton'
import MuiList from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import Tooltip from '@material-ui/core/Tooltip'
import { Typography } from '@material-ui/core';
import TextField from '@material-ui/core/TextField'
import PersonIcon from '@material-ui/icons/Person'
import EditIcon from '@material-ui/icons/Edit'
import BookIcon from '@material-ui/icons/Book'
import { errorStringsFromError } from 'utils'
import {UsersContext, SnackbarContext} from 'contexts'
import {useHistory} from 'react-router-dom'
import {useDependency, usePagination, useFilter} from 'hooks'


const List = () => {
  const users = useContext(UsersContext.ReactContext)
  const snackbar = useContext(SnackbarContext.ReactContext)

  const history = useHistory()

  const [page, setPage] = usePagination('users')
  const [filter, setFilter] = useFilter(setPage, 'users')

  const [loading, , reloadUsers] = useDependency(() => (
    users.actions.index({
      page: page,
      fields: {users: 'name,email'},
      filter,
      sort: 'name'
    })
  ), [page, filter])

  const showUser = ({id}) => () => {
    history.push(`/users/${id}`)
  }

  const editUser = ({id}) => event => {
    history.push(`/users/${id}/edit`)
    event.stopPropagation()
  }

  const editAssignedBooks = ({id}) => event => {
    history.push(`/log_books_users/assigned_books/${id}`)
    event.stopPropagation()
  }

  const deleteUser = ({id}) => event => {
    users.actions.destroy({id})
                .then(reloadUsers)
                .catch(error => snackbar.actions.show(errorStringsFromError(error).join(', ')))
  }

  const email = (user) => {
    if (user.unconfirmedEmail) {
      return `${user.unconfirmedEmail} (uncomfirmed)`
    }
    return user.email
  }

  const renderUserListItem = (user) => {
    const { name, id } = user
    return (
      <ListItem button onClick={showUser(user)} key={id}>
        <ListItemIcon>
          <PersonIcon />
        </ListItemIcon>
        <ListItemText primary={name} secondary={email(user)}/>
        <ListItemSecondaryAction>
          <Tooltip title='Assigned Log Books'>
            <IconButton onClick={editAssignedBooks(user)}><BookIcon/></IconButton>
          </Tooltip>
          <IconButton onClick={editUser(user)}><EditIcon/></IconButton>
        </ListItemSecondaryAction>
      </ListItem>
    )
  }

  return (
    <PageContainer>
      {loading && <LoaderOverlay /> }
      <PageHeader title="Users" actions={
        <Button onClick={() => history.push('/users/new')} color="secondary" variant="contained">
          Create
        </Button>
      } />
      <ErrorBanner>
        {errorStringsFromError(users.actions.apiErrorsFor('index', 'destroy'))}
      </ErrorBanner>
      <Pagination totalPages={users.totalPages} page={page} onPageSelected={setPage}
                  startAdornment={(
                    <SearchCombo searchTextMember='search' onFilterChange={setFilter} filter={filter}>
                      <TextField name="search" label="Email" fullWidth style={{ minWidth: 300 }} />
                      <TextField name="name"   label="Name" fullWidth style={{ minWidth: 300 }} />
                    </SearchCombo>
                  )}
      />
      <MuiList dense>
        {users.list.map(renderUserListItem)}
      </MuiList>
      <Pagination totalPages={users.totalPages} page={page} onPageSelected={setPage}/>
    </PageContainer>
  )
}

export default (props) => (
  <UsersContext>
    <List {...props} />
  </UsersContext>
)