import APIContext from 'contexts/base/APIContext'

export default class UsersContext extends APIContext{
  static contextName = 'users'

  static initialState = {
    ...this.initialState,
    currentUser: {},
  }

  current = async() => {
    const { data: current } = await this.performRequest('current', false)()
    this.setState({currentUser: current})
    return current
  }
}
