import React, {useContext} from 'react'
import {useDependency, useResourceFromPath} from 'hooks'
import { Link } from 'react-router-dom'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import Typography from '@material-ui/core/Typography'
import EditIcon from '@material-ui/icons/Edit'
import IconButton from '@material-ui/core/IconButton'
import { PageContainer } from 'components'
import { LogReferencesContext } from 'contexts'

const Show = () => {
  const {id} = useResourceFromPath()
  const log_references = useContext(LogReferencesContext.ReactContext)

  useDependency(() => (
    log_references.actions.show(id, {
      include: ''
    })
  ), [id])

  const reference = log_references.selected

  return (
    <PageContainer>
      <Typography variant='h4'>Reference - {reference.name}</Typography>
      <Table>
        <TableBody>
          <TableRow>
            <TableCell style={{width: 30}} variant='head'>Actions</TableCell>
            <TableCell>
              <Link to={`/log_references/${reference.id}/edit`}>
                <IconButton>
                  <EditIcon/>
                </IconButton>
              </Link>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell variant='head'>Name</TableCell>
            <TableCell>{String(reference.name || '')}</TableCell>
          </TableRow>
        </TableBody>
      </Table>
      <br/>
    </PageContainer>
  )
}

export default (props) => (
  <LogReferencesContext>
    <Show {...props} />
  </LogReferencesContext>
)
