import React, {useContext, useEffect, useState} from 'react'
import { useFormData, useInstanceSaver } from 'hooks'
import {ControlledForm, ErrorBanner, PromiseButton, AutocompleteSearch, DateTimePicker} from 'components'
import TextField from '@material-ui/core/TextField'
import { LogRecordsContext } from 'contexts'
import {errorStringsFromError} from 'utils'
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import {Box, useMediaQuery} from "@material-ui/core";
import moment from 'moment'
import Alert from '@material-ui/lab/Alert';
import AutocompleteReferences from './AutocompleteReferences';
import AutocompleteInstructions from "./AutocompleteInstructions";

import Draggable from "react-draggable";
import Paper from "@material-ui/core/Paper";

const buttonLabels = {
  new: "New",
  edit: "Edit Log",
  supersede: "Supersede Log",
}

const titles = {
  new: "New",
  edit: "Edit Log",
  supersede: "Supersede Log",
}

const saveButtonLabels = {
  new: "Save",
  edit: "Edit",
  supersede: "Supersede",
}

const PaperComponent = (props: PaperProps) => {
  return (
    <Draggable cancel={'[class*="MuiDialogContent-root"]'}>
      <Paper {...props} />
    </Draggable>
  );
}

const FormDialogButton = ({ logBook, onSave, logRecord, edit, disabled }) => {
  const logRecords = useContext(LogRecordsContext.ReactContext)
  const isSupersede = edit && moment(logRecord.editExpiresDatetime) < moment()
  const mode = edit ? (isSupersede ? "supersede" : "edit") : "new"

  const [open, setOpen] = useState(  false)

  const [formData, setFormData] = useFormData(logRecords.selected)
  const [save, errors] = useInstanceSaver(formData, logRecords, edit ? "edit" : "new",
    {
      linked: ['logBook', 'logReference', 'logInstruction', 'user'],
      redirect: false,
      saveAction: isSupersede ? logRecords.actions.supersede : undefined,
      errorsAction: isSupersede ? "supersede" : undefined,
      mapFormData: data => {
        return mode === "new" ?  ({...data, logBook}) : data}
    }
  )

  const handleSave = async () => {
    await save()
    setOpen(false)
    await onSave()
    const resetFormData = Object.fromEntries(Object.entries(formData).map(([k, v]) => [k, undefined]))
    setFormData(resetFormData)
  }

  useEffect(() => {
    if (!edit && open) {
      setFormData({eventDatetime: moment(), prefix: null, logReference: null, logInstruction: null, comments: null})
    } else if (edit && open) {
      setFormData(logRecord)
    }
  }, [open])

  const minWidth = useMediaQuery('(min-width: 600px)') ? 550 : null

  const handleCancel = () => {
    logRecords.actions.clearErrors()
    setOpen(false)
  }

  return (
    <>
      <Button onClick={() => setOpen(prevState => !prevState)} color="secondary" variant="contained" disabled={disabled}>
        {buttonLabels[mode]}
      </Button>
      <Dialog 
        open={open}
        PaperComponent={PaperComponent}
       >
        <ControlledForm data={formData} errors={errors} onChange={setFormData} onSubmit={handleSave}>
          <DialogTitle>
            {titles[mode]}
          </DialogTitle>
          <DialogContent>
            <Box minWidth={minWidth}>
              <ErrorBanner>{errorStringsFromError(logRecords.actions.apiErrorsFor('supersede', 'create', 'update'))}</ErrorBanner>
              {isSupersede &&
                <Box mb={2}>
                  <Alert severity="warning">Warning: the existing log will be superseded</Alert>
                </Box>
              }
              <DateTimePicker fullWidth name='eventDatetime' label="Actual Date Time"/>
              <AutocompleteReferences logBook={logBook} name="logReference" active />
              <TextField fullWidth name='prefix'/>
              <AutocompleteInstructions logBook={logBook} name="logInstruction" active />
              <TextField fullWidth name='comments' multiline rows={3}/>
            </Box>
          </DialogContent>
          <DialogActions>
            <Button variant='contained' color='primary' onClick={handleCancel}>
              Cancel
            </Button>
            <PromiseButton type='submit' color='secondary' variant='contained'>
              {saveButtonLabels[mode]}
            </PromiseButton>
          </DialogActions>
        </ControlledForm>
      </Dialog>
    </>
  )
}

export default FormDialogButton