import React, { Component } from 'react'
import PropTypes from 'prop-types'
import TextField from '@material-ui/core/TextField'
import Tooltip from '@material-ui/core/Tooltip'
import FilterListIcon from '@material-ui/icons/FilterList'
import SearchIcon from '@material-ui/icons/Search'
import IconButton from '@material-ui/core/IconButton'
import InputAdornment from '@material-ui/core/InputAdornment';
import withStyles from 'styles'

export class FilteredSearchInput extends Component{

  static propTypes = {
    className:         PropTypes.string,
    onChange:          PropTypes.func.isRequired,
    onOpenFilters:     PropTypes.func.isRequired,
    hasFiltersApplied: PropTypes.bool
  }

  static defaultProps = {
    className: '',
    hasFiltersApplied: false
  }

  render = () => {
    const { className, classes, onChange, onOpenFilters, hasFiltersApplied, ...textFieldProps } = this.props
    return (
      <TextField
        className={classes('searchInput')}
        fullWidth
        placeholder="Search"
        onChange={onChange}
        InputProps={{
          disableUnderline: true,
          startAdornment:
            <InputAdornment position="start">
              <SearchIcon/>
            </InputAdornment>,
          endAdornment: (
            <InputAdornment>
              <Tooltip title='Advanced Filters'>
                <IconButton style={{color: hasFiltersApplied && classes('searchInput').color}} onClick={onOpenFilters}>
                  <FilterListIcon/>
                </IconButton>
              </Tooltip>
            </InputAdornment>
          )
        }}
        {...textFieldProps}
      />
    )
  }
}

const styles = ({palette, overrides}) => ({
  searchInput: {
    border: '1px solid silver',
    padding: '5px 10px',
    width: 280,
    marginRight: 10,
    marginBottom: 0,
    color: palette.secondary.main,
    flexDirection: 'row',
    '@media print': {
      display: 'none',
    },
  }
})

export default withStyles(styles, {name: 'FilteredSearchInput'})(FilteredSearchInput)
