import React, { useContext } from 'react'
import { Pagination, ErrorBanner, FABFixed, PageContainer, PageHeader, SearchCombo, Select, LoaderOverlay } from 'components'
import IconButton from '@material-ui/core/IconButton'
import Button from "@material-ui/core/Button";
import AddIcon from '@material-ui/icons/Add'
import MuiList from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import TextField from '@material-ui/core/TextField'
import MenuItem from '@material-ui/core/MenuItem'
import EditIcon from '@material-ui/icons/Edit'
import BookIcon from '@material-ui/icons/Book';
import { errorStringsFromError } from 'utils'
import { LogBooksContext } from 'contexts'
import { usePagination, useDependency, useFilter } from 'hooks'
import { useHistory } from 'react-router-dom'
import Tooltip from '@material-ui/core/Tooltip'
import BookmarkIcon from '@material-ui/icons//Bookmark';
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';

const List = () => {
  const logBooks = useContext(LogBooksContext.ReactContext)

  const history = useHistory()

  const [page, setPage] = usePagination('logBooks')
  const [filter, setFilter] = useFilter(setPage, 'logBooks')

  const [loading,,] = useDependency(() => (
    logBooks.actions.index({
      page: page,
      fields: {logBooks: 'title,activeFlag'},
      filter,
      sort: 'title'
    })
  ), [page, filter])

  const showLogBook = ({id}) => () => {
    history.push(`/log_books/${id}`)
  }
  const editAssignedInstructions= ({id}) => event => {
    history.push(`/log_books_instructions/assigned_instructions/${id}`)
    event.stopPropagation()
  }
  const editAssignedReferences = ({id}) => event => {
    history.push(`/log_books_references/assigned_references/${id}`)
    event.stopPropagation()
  }

  const editLogBook = ({id}) => event => {
    history.push(`/log_books/${id}/edit`)
    event.stopPropagation()
  }

  const renderLogBookListItem = (logBook) => {
    const { id, title, activeFlag } = logBook
    const secondaryText = activeFlag === 'Y' ? null : 'Inactive'
    return (
      <ListItem button onClick={showLogBook(logBook)} key={id}>
        <ListItemIcon>
          <BookIcon />
        </ListItemIcon>
        <ListItemText primary={title} secondary={secondaryText} />
        <ListItemSecondaryAction>
          <Tooltip title='Assigned Log References'>
            <IconButton onClick={editAssignedReferences(logBook)}><BookmarkIcon/></IconButton>
          </Tooltip>
          <Tooltip title='Assigned Log Instructions'>
            <IconButton onClick={editAssignedInstructions(logBook)}><ArrowRightAltIcon/></IconButton>
          </Tooltip>
          <IconButton onClick={editLogBook(logBook)}><EditIcon/></IconButton>
        </ListItemSecondaryAction>
      </ListItem>
    )
  }

  return (
    <PageContainer>
      {loading && <LoaderOverlay /> }
      <PageHeader title="Log Books" actions={
        <Button onClick={() => history.push('/log_books/new')} color="secondary" variant="contained">
          Create
        </Button>
      } />

      <ErrorBanner>
        {errorStringsFromError(logBooks.actions.apiErrorsFor('index', 'destroy'))}
      </ErrorBanner>
      <Pagination totalPages={logBooks.totalPages} page={page} onPageSelected={setPage}
                  startAdornment={(
                    <SearchCombo searchTextMember='search' onFilterChange={setFilter} filter={filter}>
                      <TextField name="search" label="Title" fullWidth style={{ minWidth: 300 }} />
                      <Select name="activeFlag" fullWidth>
                        <MenuItem value="Y">Yes</MenuItem>
                        <MenuItem value="N">No</MenuItem>
                      </Select>
                    </SearchCombo>
                  )}
      />
      <MuiList dense>
        {logBooks.list.map(renderLogBookListItem )}
      </MuiList>
      <Pagination totalPages={logBooks.totalPages} page={page} onPageSelected={setPage}/>
    </PageContainer>
  )
}

export default (props) => (
  <LogBooksContext>
    <List {...props} />
  </LogBooksContext>
)