import React, {useContext} from 'react'
import { useFormData, useDependency, useInstanceSaver, useResourceFromPath } from 'hooks'
import {ControlledForm, ErrorBanner, PageContainer, PromiseButton} from 'components'
import Typography from '@material-ui/core/Typography'
import TextField from '@material-ui/core/TextField'
import { LogInstructionsContext } from 'contexts'
import {errorStringsFromError} from 'utils'

const Form = () => {
  const {id, action} = useResourceFromPath()
  const log_instructions = useContext(LogInstructionsContext.ReactContext)

  useDependency(() => {
    if(action === 'edit'){
      return log_instructions.actions.show(id)
    }
  }, [id, action])

  const [formData, setFormData] = useFormData(log_instructions.selected)
  const [save, errors] = useInstanceSaver(formData, log_instructions, action)

  return (
    <PageContainer>
      <Typography variant='h5'>{action === 'edit' ? `Edit Instruction - ${formData.name}` : 'Create a new Log Instruction'}</Typography>
      <ControlledForm data={formData} errors={errors} onChange={setFormData} onSubmit={save}>
        <ErrorBanner>{errorStringsFromError(log_instructions.actions.apiErrorsFor('create', 'update'))}</ErrorBanner>
        <TextField fullWidth name='name'/>
      <PromiseButton color='secondary' fullWidth variant='contained' type='submit'>Save</PromiseButton>
      </ControlledForm>
    </PageContainer>
  )
}

export default (props) => (
  <LogInstructionsContext>
    <Form {...props} />
  </LogInstructionsContext>
)