import React, { useContext } from 'react'
import { Pagination, ErrorBanner, PageContainer, DeleteConfirmationDialog, PageHeader, SearchCombo, AutocompleteSearch, LoaderOverlay } from 'components'
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import {makeStyles} from "@material-ui/core";
import { errorStringsFromError } from 'utils'
import { SnackbarContext, LogBooksUsersContext } from 'contexts'
import { usePagination, useDependency, useFilter } from 'hooks'

const useStyles = makeStyles({
  actionButton: {
    float: 'right',
  },
});


const List = () => {
  const logBooksUsers = useContext(LogBooksUsersContext.ReactContext)
  const snackbar = useContext(SnackbarContext.ReactContext)

  const [page, setPage] = usePagination('logBooksUsers')
  const [filter, setFilter] = useFilter(setPage, 'logBooksUsers')


  const [loading, , reloadlogBooksUsers] = useDependency(() => (
    logBooksUsers.actions.index({
      page: page,
      include: 'user,logBook',
      filter: {...filter, active: true}
    })
  ), [page, filter])

  const deletelogBooksUser = ({id}) => _event => {
    logBooksUsers.actions.destroy({id})
                .then(reloadlogBooksUsers)
                .catch(error => snackbar.actions.show(errorStringsFromError(error).join(', ')))
  }

  const classes = useStyles()

  return (
    <PageContainer>
      {loading && <LoaderOverlay /> }
      <PageHeader title="Log Book Permissions" />
      <ErrorBanner>
        {errorStringsFromError(logBooksUsers.actions.apiErrorsFor('index', 'destroy'))}
      </ErrorBanner>
      <Pagination totalPages={logBooksUsers.totalPages} page={page} onPageSelected={setPage}
                  startAdornment={(
                    <SearchCombo searchTextMember='search' onFilterChange={setFilter} filter={filter}>
                      <AutocompleteSearch name="user" style={{ minWidth: 300 }} />
                      <AutocompleteSearch name="logBook" searchableField="title" />
                    </SearchCombo>
                  )}
      />

      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>User</TableCell>
            <TableCell>Log Book</TableCell>
            <TableCell className={ classes.actionButton }>Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {logBooksUsers.list.map(lbu => (
            <TableRow key={lbu.id} hover>
              <TableCell>{lbu.user.email}</TableCell>
              <TableCell>{lbu.logBook.title}</TableCell>
              <TableCell className={ classes.actionButton }>
                <DeleteConfirmationDialog size="small" onConfirm={deletelogBooksUser(lbu)} entityName="permission" />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <Pagination totalPages={logBooksUsers.totalPages} page={page} onPageSelected={setPage}/>
    </PageContainer>
  )
}

export default (props) => (
  <LogBooksUsersContext>
    <List {...props} />
  </LogBooksUsersContext>
)