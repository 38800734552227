import React, {useEffect, useState} from "react";
import CheckList from "./CheckList";

export type Id = string
export type Label = string
export type Labels = Record<Id, Label>
export type State = Record<Id, boolean>
export type AssignListProps = {
  labels: Labels;
  initialState: State;
  onSave: (State) => void;
}

// This is what <CheckList /> expects
type Item = {
  id: Id;
  title: Label;
  checked: boolean;
}


// Note that this component won't reset checked state if initialState or labels change
// so pagination is supported by default
const AssignList = ({ labels, initialState, onSave  }: AssignListProps) => {
  const [checked, setChecked] = useState<State>({})
  const [initial, setInitial] = useState<State>(initialState)

  useEffect(() => {
    setInitial(prevState => (
      {...prevState, ...initialState}
    ))
  }, [initialState])

  const handleToggle = (item: Item) => {
    setChecked(prevState => ({...prevState, [item.id]: !item.checked}))
  }

  const items: Item[] = Object.entries(labels).map(([id, label]) => ({
    id,
    title: label,
    checked: checked[id] ?? initial[id] ?? false
  })).sort((a, b) => a.title.toUpperCase() > b.title.toUpperCase() ? 1 : -1)

  return <CheckList items={items} onSave={() => onSave(checked)} onReset={() => setChecked({})} onToggle={handleToggle} />
}

export default AssignList