import React from 'react'
import { Switch, Redirect } from 'react-router-dom'
import * as Containers from 'containers'
import { Route } from 'containers'
import { Authorization  } from 'utils'

const Routes = () => {
  return (
    <Switch>
      <Route exact path="/log_books_instructions/assigned_instructions/:log_book_id" component={Containers.LogBooksInstruction.AssignedInstructions} requires={Authorization.isAdmin}/>
      <Route exact path="/log_books_references/assigned_references/:log_book_id" component={Containers.LogBooksReference.AssignedReferences} requires={Authorization.isAdmin}/>
      <Route exact path="/log_books"                  component={Containers.LogBook.List} requires={Authorization.isAdmin}/>
      <Route exact path="/log_books/new"              component={Containers.LogBook.Form} requires={Authorization.isAdmin}/>
      <Route exact path="/log_books/:id"              component={Containers.LogBook.Show} requires={Authorization.isAdmin}/>
      <Route exact path="/log_books/:id/edit"         component={Containers.LogBook.Form} requires={Authorization.isAdmin}/>
      <Route exact path="/log_instructions"               component={Containers.LogInstruction.List}  requires={Authorization.isAdmin}/>
      <Route exact path="/log_instructions/new"           component={Containers.LogInstruction.Form}  requires={Authorization.isAdmin}/>
      <Route exact path="/log_instructions/:id"           component={Containers.LogInstruction.Show}  requires={Authorization.isAdmin}/>
      <Route exact path="/log_instructions/:id/edit"      component={Containers.LogInstruction.Form}  requires={Authorization.isAdmin}/>
      <Route exact path="/log_references"                 component={Containers.LogReference.List}    requires={Authorization.isAdmin}/>
      <Route exact path="/log_references/new"             component={Containers.LogReference.Form}    requires={Authorization.isAdmin}/>
      <Route exact path="/log_references/:id"             component={Containers.LogReference.Show}    requires={Authorization.isAdmin}/>
      <Route exact path="/log_references/:id/edit"        component={Containers.LogReference.Form}    requires={Authorization.isAdmin}/>
      <Route exact path="/log_books_users"            component={Containers.LogBooksUser.List} requires={Authorization.isAdmin}/>
      <Route exact path="/log_books_users/assigned_books/:user_id"  component={Containers.LogBooksUser.AssignedBooks} requires={Authorization.isAdmin}/>
      <Route exact path="/profile"                    component={Containers.User.Profile} />
      <Route exact path="/users"                      component={Containers.User.List}  requires={Authorization.isAdmin} />
      <Route exact path="/users/new"                  component={Containers.User.Form}  requires={Authorization.isAdmin} />
      <Route exact path="/users/:id"                  component={Containers.User.Show}  requires={Authorization.isAdmin} />
      <Route exact path="/users/:id/edit"             component={Containers.User.Form}  requires={Authorization.isAdmin} />
      <Route exact path="/audits"                     component={Containers.Audit.List} requires={Authorization.isAdmin} />
      <Route exact path="/"                           component={() =>  <Redirect to={  Authorization.canRead ?  "/log_records" : "/profile" }/>}/>
      <Route exact path="/log_records"                component={Containers.LogRecord.Logs} requires={Authorization.canRead}/>
      <Route exact path="/not_found"                  component={Containers.Status.NotFound} />
      <Route exact path="/unauthorized"               component={Containers.Status.Unauthorized} />
      <Route exact path="/apidocs"                    component={Containers.Docs.ApiDocs} requires={Authorization.isDeveloper} />
      <Route component={Containers.Status.NotFound} />
    </Switch>
  )
}

export default Routes