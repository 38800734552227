// DO NOT edit this file, add extentions in index.js.
// Changes to this file will be overwritten when regenerating

import { ProtectedJsonAPI } from './ProtectedJsonAPI'

export class ApiPathError extends Error {
  constructor(action, params) {
    super(`No route for ${action} matches the provided params: ${JSON.stringify(params)}`)
    this.action = action
    this.params = params
  }
}

export const API = ProtectedJsonAPI.extend(
  '',
  {
    routeNotFound: endpoint => endpoint.path(() => {
      return '/'
    }),
    tag: endpoint => endpoint.path(() => {
      return '/tag'
    }),
  }
)

export const Users = ProtectedJsonAPI.extend(
  '',
  {
    current: endpoint => endpoint.method('post').path(() => {
      return '/users/current'
    }),
    index: endpoint => endpoint.path(() => {
      return '/users'
    }),
    create: endpoint => endpoint.method('post').path(() => {
      return '/users'
    }),
    show: endpoint => endpoint.path(({id}) => {
      if (id) {
        return `/users/${id}`
      }
      throw new ApiPathError('show', {id})
    }),
    update: endpoint => endpoint.method('put').path(({id}) => {
      if (id) {
        return `/users/${id}`
      }
      throw new ApiPathError('update', {id})
    }),
    destroy: endpoint => endpoint.method('delete').path(({id}) => {
      if (id) {
        return `/users/${id}`
      }
      throw new ApiPathError('destroy', {id})
    }),
  }
)

export const LogBooks = ProtectedJsonAPI.extend(
  '',
  {
    index: endpoint => endpoint.path(() => {
      return '/log_books'
    }),
    create: endpoint => endpoint.method('post').path(() => {
      return '/log_books'
    }),
    show: endpoint => endpoint.path(({id}) => {
      if (id) {
        return `/log_books/${id}`
      }
      throw new ApiPathError('show', {id})
    }),
    update: endpoint => endpoint.method('put').path(({id}) => {
      if (id) {
        return `/log_books/${id}`
      }
      throw new ApiPathError('update', {id})
    }),
  }
)

export const LogRecords = ProtectedJsonAPI.extend(
  '',
  {
    supersede: endpoint => endpoint.method('post').path(({id}) => {
      if (id) {
        return `/log_records/${id}/supersede`
      }
      throw new ApiPathError('supersede', {id})
    }),
    index: endpoint => endpoint.path(() => {
      return '/log_records'
    }),
    create: endpoint => endpoint.method('post').path(() => {
      return '/log_records'
    }),
    show: endpoint => endpoint.path(({id}) => {
      if (id) {
        return `/log_records/${id}`
      }
      throw new ApiPathError('show', {id})
    }),
    update: endpoint => endpoint.method('put').path(({id}) => {
      if (id) {
        return `/log_records/${id}`
      }
      throw new ApiPathError('update', {id})
    }),
  }
)

export const LogReferences = ProtectedJsonAPI.extend(
  '',
  {
    index: endpoint => endpoint.path(() => {
      return '/log_references'
    }),
    create: endpoint => endpoint.method('post').path(() => {
      return '/log_references'
    }),
    show: endpoint => endpoint.path(({id}) => {
      if (id) {
        return `/log_references/${id}`
      }
      throw new ApiPathError('show', {id})
    }),
    update: endpoint => endpoint.method('put').path(({id}) => {
      if (id) {
        return `/log_references/${id}`
      }
      throw new ApiPathError('update', {id})
    }),
    destroy: endpoint => endpoint.method('delete').path(({id}) => {
      if (id) {
        return `/log_references/${id}`
      }
      throw new ApiPathError('destroy', {id})
    }),
  }
)

export const LogInstructions = ProtectedJsonAPI.extend(
  '',
  {
    index: endpoint => endpoint.path(() => {
      return '/log_instructions'
    }),
    create: endpoint => endpoint.method('post').path(() => {
      return '/log_instructions'
    }),
    show: endpoint => endpoint.path(({id}) => {
      if (id) {
        return `/log_instructions/${id}`
      }
      throw new ApiPathError('show', {id})
    }),
    update: endpoint => endpoint.method('put').path(({id}) => {
      if (id) {
        return `/log_instructions/${id}`
      }
      throw new ApiPathError('update', {id})
    }),
    destroy: endpoint => endpoint.method('delete').path(({id}) => {
      if (id) {
        return `/log_instructions/${id}`
      }
      throw new ApiPathError('destroy', {id})
    }),
  }
)

export const LogBooksInstructions = ProtectedJsonAPI.extend(
  '',
  {
    set: endpoint => endpoint.method('post').path(() => {
      return '/log_books_instructions'
    }),
    index: endpoint => endpoint.path(() => {
      return '/log_books_instructions'
    }),
    destroy: endpoint => endpoint.method('delete').path(({id}) => {
      if (id) {
        return `/log_books_instructions/${id}`
      }
      throw new ApiPathError('destroy', {id})
    }),
  }
)

export const LogBooksReferences = ProtectedJsonAPI.extend(
  '',
  {
    set: endpoint => endpoint.method('post').path(() => {
      return '/log_books_references'
    }),
    index: endpoint => endpoint.path(() => {
      return '/log_books_references'
    }),
    destroy: endpoint => endpoint.method('delete').path(({id}) => {
      if (id) {
        return `/log_books_references/${id}`
      }
      throw new ApiPathError('destroy', {id})
    }),
  }
)

export const LogBooksUsers = ProtectedJsonAPI.extend(
  '',
  {
    set: endpoint => endpoint.method('post').path(() => {
      return '/log_books_users'
    }),
    index: endpoint => endpoint.path(() => {
      return '/log_books_users'
    }),
    destroy: endpoint => endpoint.method('delete').path(({id}) => {
      if (id) {
        return `/log_books_users/${id}`
      }
      throw new ApiPathError('destroy', {id})
    }),
  }
)

export const Audits = ProtectedJsonAPI.extend(
  '',
  {
    index: endpoint => endpoint.path(() => {
      return '/audits'
    }),
  }
)
