import React from 'react'
import CardContent from '@material-ui/core/CardContent'
import {CenteredCard} from 'components'

const StatusPage = ({children}) => {
  return (
    <CenteredCard centerVertically={false}>
      <CardContent>
        {children}
      </CardContent>
    </CenteredCard>
  )
}

export default StatusPage