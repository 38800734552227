import React, {useContext} from 'react'
import { Pagination, ErrorBanner, PageContainer, PageHeader, AssignList, SearchCombo, LabeledSelect, LoaderOverlay} from 'components'
import { errorStringsFromError } from 'utils'
import { SnackbarContext, LogBooksInstructionsContext, LogBooksContext, LogInstructionsContext } from 'contexts'
import { usePagination, useDependency, useFilter } from 'hooks'
import {useRouteMatch} from 'react-router-dom'
import TextField from '@material-ui/core/TextField';


const AssignedBooks = () => {
  const logBooksInstructions = useContext(LogBooksInstructionsContext.ReactContext)
  const logInstructions = useContext(LogInstructionsContext.ReactContext)
  const logBooks = useContext(LogBooksContext.ReactContext)
  const snackbar = useContext(SnackbarContext.ReactContext)
  const { params: { log_book_id: logBookId } } = useRouteMatch()
  const [page, setPage] = usePagination('logInstructions')
  const [filter, setFilter] = useFilter(setPage,'logInstructions')

  const [loading,,] = useDependency(() => (
    logBooksInstructions.actions.index({
      pageSize: 10000,
      include: 'logInstruction,logBook',
      filter: { logBookId, activeFlag:"Y" },
    })
  ), [])

  useDependency(() => (
    logInstructions.actions.index({
      page: page,
      filter,
      sort: `${filter.sortDir === "desc" ? "-" : ""}${filter.sortBy || "name"}`,
    })
  ), [page,filter])
  useDependency(() => (
    logBooks.actions.show(logBookId)
  ), [])

  const handleSave = async (checkList) => {
    try {
      await logBooksInstructions.actions.set({
        logBookId,
        permissions: Object.entries(checkList).map(([id, checked]) => ({log_instruction_id: id, checked}))
      }, { include: 'logInstruction,logBook'}
      )
      snackbar.actions.show('Permissions saved')
    } catch (e) {
      console.error(e)
      snackbar.actions.show('Failed to save permissions')
    }
  }

  const labels = Object.fromEntries(logInstructions.list.map(lb => [lb.id, lb.name]))
  const initialState = Object.fromEntries(logBooksInstructions.list.map(p => [p.logInstruction.id, true]))

  return (
    <PageContainer>
      {loading && <LoaderOverlay /> }
      <PageHeader title={`Instructions assigned to ${logBooks.selected.title || 'log book'}`} />
      <ErrorBanner>
        {errorStringsFromError(logBooksInstructions.actions.apiErrorsFor('index', 'destroy'))}
      </ErrorBanner>
      <Pagination totalPages={logInstructions.totalPages} page={page} onPageSelected={setPage}
        startAdornment={(
          <SearchCombo searchTextMember='name' onFilterChange={setFilter} filter={filter}>
            <TextField name="name" label="Name" fullWidth style={{ minWidth: 300 }} />
            <LabeledSelect name="sortDir" label="Sort Direction" style={{width: "50%"}} options={{"asc": "Ascending", "desc": "Descending"}} />
          </SearchCombo>
        )}
        />
      <AssignList labels={labels} initialState={initialState} onSave={handleSave} />
      <Pagination totalPages={logInstructions.totalPages} page={page} onPageSelected={setPage}/>
    </PageContainer>
  )
}

export default (props) => (
  <LogBooksContext>
    <LogInstructionsContext>
      <LogBooksInstructionsContext>
        <AssignedBooks {...props} />
      </LogBooksInstructionsContext>
    </LogInstructionsContext>
  </LogBooksContext>
)