import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Loader } from '.'

export class LoaderOverlay extends Component {

  render = () =>
    <div style={{
      height: '50%',
      width: '100%',
      minHeight: 150,
      position: 'absolute',
      left: 0,
      zIndex: 3
    }}>
      <div >
        {<Loader size={40} color={this.props.color}/>}
      </div>
    </div>
}

export default LoaderOverlay
