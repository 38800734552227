import React, { useContext } from 'react'
import { Pagination, ErrorBanner, PageContainer, PageHeader, SearchCombo, LoaderOverlay } from 'components'
import AddIcon from '@material-ui/icons/Add'
import Button from "@material-ui/core/Button";
import IconButton from '@material-ui/core/IconButton'
import MuiList from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import PersonIcon from '@material-ui/icons/Person'
import EditIcon from '@material-ui/icons/Edit'
import { Typography } from '@material-ui/core';
import TextField from '@material-ui/core/TextField'
import { errorStringsFromError } from 'utils'
import { LogInstructionsContext } from 'contexts'
import { usePagination, useDependency, useFilter } from 'hooks'
import { useHistory } from 'react-router-dom'
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';

const List = () => {
  const log_instructions = useContext(LogInstructionsContext.ReactContext)
  const history = useHistory()

  const [page, setPage] = usePagination('log_instructions')
  const [filter,setFilter] = useFilter(setPage, 'log_instructions')
  const [loading, , reloadInstructions] = useDependency(() => (
    log_instructions.actions.index({
      page: page,
      filter: filter,
      fields: {log_instructions: 'name'},
      sort: 'name'
    })
  ), [page,filter])

  const showInstruction = ({id}) => () => {
    history.push(`/log_instructions/${id}`)
  }

  const editInstruction = ({id}) => event => {
    history.push(`/log_instructions/${id}/edit`)
    event.stopPropagation()
  }

  const renderInstructionListItem = (log_instruction) => {
    const { id, name } = log_instruction
    return (
      <ListItem button onClick={showInstruction(log_instruction)} key={id}>
        <ListItemIcon>
          <ArrowRightAltIcon />
        </ListItemIcon>
        <ListItemText primary={name}/>
        <ListItemSecondaryAction>
          <IconButton onClick={editInstruction(log_instruction)}><EditIcon/></IconButton>
        </ListItemSecondaryAction>
      </ListItem>
    )
  }

  return (
    <PageContainer>
      {loading && <LoaderOverlay /> }
      <PageHeader title="Log Instructions" actions={
        <Button onClick={() => history.push('/log_instructions/new')} color="secondary" variant="contained">
          Create
        </Button>
      } />
      <ErrorBanner>
        {errorStringsFromError(log_instructions.actions.apiErrorsFor('index', 'destroy'))}
      </ErrorBanner>
      <Pagination totalPages={log_instructions.totalPages} page={page} onPageSelected={setPage}
        startAdornment={(
          <SearchCombo searchTextMember='name' onFilterChange={setFilter} filter={filter}>
            <TextField name="search" label="Name" fullWidth style={{ minWidth: 300 }} />
          </SearchCombo>
        )}

      />
      <MuiList dense>
        {log_instructions.list.map(renderInstructionListItem )}
      </MuiList>
      <Pagination totalPages={log_instructions.totalPages} page={page} onPageSelected={setPage}/>
    </PageContainer>
  )
}

export default (props) => (
  <LogInstructionsContext>
    <List {...props} />
  </LogInstructionsContext>
)