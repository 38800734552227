import React, {useContext} from 'react'
import { ErrorBanner, PageContainer, PageHeader, AssignList, LoaderOverlay } from 'components'
import { errorStringsFromError } from 'utils'
import { SnackbarContext, LogBooksUsersContext, UsersContext, LogBooksContext } from 'contexts'
import { useDependency } from 'hooks'
import {useRouteMatch} from 'react-router-dom'


const AssignedBooks = () => {
  const logBooksUsers = useContext(LogBooksUsersContext.ReactContext)
  const users = useContext(UsersContext.ReactContext)
  const logBooks = useContext(LogBooksContext.ReactContext)
  const snackbar = useContext(SnackbarContext.ReactContext)
  const { params: { user_id: userId } } = useRouteMatch()

  useDependency(() => (
    logBooksUsers.actions.index({
      pageSize: 10000,
      include: 'user,logBook',
      filter: { userId, active: true },
    })
  ), [])

  const [loading,,] = useDependency(() => (
    logBooks.actions.index({
      pageSize: 10000,
      sort: 'title',
    })
  ), [])

  useDependency(() => (
    users.actions.show(userId)
  ), [])

  const handleSave = async (checkList) => {
    try {
      await logBooksUsers.actions.set({
        userId,
        permissions: Object.entries(checkList).map(([id, checked]) => ({log_book_id: id, checked}))
      }, {include: 'user,logBook'})
      snackbar.actions.show('Permissions saved')
    } catch (e) {
      console.error(e)
      snackbar.actions.show('Failed to save permissions')
    }
  }

  const labels = Object.fromEntries(logBooks.list.map(lb => [lb.id, lb.title]))
  const initialState = Object.fromEntries(logBooksUsers.list.map(p => [p.logBook.id, true]))

  return (
    <PageContainer>
      {loading && <LoaderOverlay /> }
      <PageHeader title={`Log Books assigned to ${users.selected.email || 'user'}`} />
      <ErrorBanner>
        {errorStringsFromError(logBooksUsers.actions.apiErrorsFor('index', 'destroy'))}
      </ErrorBanner>
      <AssignList labels={labels} initialState={initialState} onSave={handleSave} />
    </PageContainer>
  )
}

export default (props) => (
  <UsersContext>
    <LogBooksContext>
      <LogBooksUsersContext>
        <AssignedBooks {...props} />
      </LogBooksUsersContext>
    </LogBooksContext>
  </UsersContext>
)