import APIResource    from 'services/APIResource'
import * as API from 'api'
import { theme } from 'styles'
import { Authorization } from 'utils'
import { ContextsByType, Actions } from 'contexts/base/BaseContext'
import * as Utils from 'utils'
import moment from 'moment'
import qs from 'qs'

let devToolsInitialised = false

export function sendDevObjectsToTopLevel(){
  if(devToolsInitialised) return
  devToolsInitialised = true
  defineWindowProp('APIResource', APIResource)
  defineWindowProp('API', API)
  defineWindowProp('theme', theme)
  defineWindowProp('Authorization', Authorization)
  defineWindowProp('Contexts', ContextsByType)
  defineWindowProp('Actions', Actions)
  defineWindowProp('Utils', Utils)
  defineWindowProp('moment', moment)
  defineWindowProp('qs', qs)
}

const defineWindowProp = (name, prop) => {
  Object.defineProperty(window, name, {get(){
    console.warn('Direct access of top level objects. These accessors should be used from within the console only')
    return prop
  }})
}

setTimeout(sendDevObjectsToTopLevel)