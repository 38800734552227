import Typography from "@material-ui/core/Typography"
import React, {useState} from "react"
import ConfirmationDialog from "./ConfirmationDialog"
import IconButton from "@material-ui/core/IconButton"
import DeleteIcon from "@material-ui/icons/Delete"
import PropTypes from "prop-types"


const DeleteConfirmationDialog = ({ onConfirm, entityName, size }) => {
  const [open, setOpen] = useState(false)
  return (
      <>
        <IconButton size={size} onClick={event => {
          event.stopPropagation()
          setOpen(true)
        }}>
          <DeleteIcon />
        </IconButton>
        <ConfirmationDialog
            canCancel
            onConfirm={() => {
              setOpen(false)
              onConfirm && onConfirm()
            }}
            onCancel={() => setOpen(false)}
            open={open}
            title={`Confirm ${entityName || 'record'} removal`}
            confirmLabel="Remove"
        >
          <Typography variant="body2">
            Are you sure you want to remove this {entityName || 'record'}?
          </Typography>
        </ConfirmationDialog>
      </>
  )
}

DeleteConfirmationDialog.propTypes = {
  onConfirm: PropTypes.func,
  entityName: PropTypes.string
}

export default DeleteConfirmationDialog