import React, {useContext} from 'react'
import { Link } from 'react-router-dom'
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography'
import EditIcon from '@material-ui/icons/Edit'
import IconButton from '@material-ui/core/IconButton'
import {PageContainer} from 'components'
import {UsersContext} from 'contexts'
import {useDependency, useResourceFromPath} from 'hooks'

const Show = () => {
  const {id} = useResourceFromPath()
  const users = useContext(UsersContext.ReactContext)

  useDependency(() => {
    return users.actions.show(id)
  }, [id])

  const user = users.selected

  return (
    <PageContainer>
      <Typography variant='h4'>User - {user.name}</Typography>
      <Table>
        <TableBody>
          <TableRow>
            <TableCell style={{width: 30}} variant='head'>Actions</TableCell>
            <TableCell>
              <Link to={`/users/${user.id}/edit`}>
                <IconButton>
                  <EditIcon/>
                </IconButton>
              </Link>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell variant='head'>Name</TableCell>
            <TableCell>{String(user.name || '')}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell variant='head'>Email</TableCell>
            <TableCell>{String(user.email || '')}</TableCell>
          </TableRow>
            <TableRow>
              <TableCell variant='head'>Network Id</TableCell>
              <TableCell>{String(user.networkId || '')}</TableCell>
            </TableRow>
        </TableBody>
      </Table>
    </PageContainer>
  )
}

export default (props) => (
  <UsersContext>
    <Show {...props} />
  </UsersContext>
)