import moment from 'moment'

export function userFriendlyDate(date){
  return moment(date).format('DD MMM YYYY, HH:mm')
}

export function roundDateOrMoment(date, nearestMins){
  const duration = moment.duration(nearestMins, 'minute')
  return moment(Math.floor((+date) / (+duration)) * (+duration))
}

export function formatLocaleDate(date, {includeYear=true}={}){
  return moment(date).format(`DD/MM${includeYear ? '/YYYY' : ''}`)
}

export function formatLocaleDateTime(date, {includeYear=true, includeSeconds=false}={}){
  return moment(date).format(`DD/MM${includeYear ? '/YYYY' : ''} HH:mm${includeSeconds ? ':ss' : ''}`)
}

export const startOfToday = () => {
  return moment().startOf('day')
}

export const endOfToday = () => {
  return moment().endOf('day')
}
