import React, { useContext } from 'react'
import { Pagination, ErrorBanner, PageContainer, SearchCombo, PageHeader, LoaderOverlay } from 'components'
import IconButton from '@material-ui/core/IconButton'
import Button from "@material-ui/core/Button";
import MuiList from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import TextField from '@material-ui/core/TextField';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import EditIcon from '@material-ui/icons/Edit'
import BookmarkIcon from '@material-ui/icons//Bookmark';
import { errorStringsFromError } from 'utils'
import { LogReferencesContext } from 'contexts'
import { usePagination, useDependency, useFilter } from 'hooks'
import { useHistory } from 'react-router-dom'
import { Typography } from '@material-ui/core';

const List = () => {
  const log_references = useContext(LogReferencesContext.ReactContext)

  const history = useHistory()

  const [page, setPage] = usePagination('log_references')
  const [filter, setFilter] = useFilter(setPage,'log_references')

  const [loading, , reloadReferences] = useDependency(() => (
    log_references.actions.index({
      page: page,
      filter:filter,
      fields: {log_references: 'name'},
      sort: 'name'
    })
  ), [page,filter])

  const showReference = ({id}) => () => {
    history.push(`/log_references/${id}`)
  }

  const editReference = ({id}) => event => {
    history.push(`/log_references/${id}/edit`)
    event.stopPropagation()
  }

  const renderReferenceListItem = (reference) => {
    const { id, name } = reference
    return (
      <ListItem button onClick={showReference(reference)} key={id}>
        <ListItemIcon>
          <BookmarkIcon />
        </ListItemIcon>
        <ListItemText primary={name}/>
        <ListItemSecondaryAction>
          <IconButton onClick={editReference(reference)}><EditIcon/></IconButton>
        </ListItemSecondaryAction>
      </ListItem>
    )
  }

  return (
    <PageContainer>
      {loading && <LoaderOverlay /> }
      <PageHeader title="Log References" actions={
        <Button onClick={() => history.push('/log_references/new')} color="secondary" variant="contained">
          Create
        </Button>
      } />
      <ErrorBanner>
        {errorStringsFromError(log_references.actions.apiErrorsFor('index', 'destroy'))}
      </ErrorBanner>
      <Pagination totalPages={log_references.totalPages} page={page} onPageSelected={setPage}
        startAdornment={(
          <SearchCombo searchTextMember='name' onFilterChange={setFilter} filter={filter}>
            <TextField name="name" label="Name" fullWidth style={{ minWidth: 300 }} />
          </SearchCombo>
        )}
      />
      <MuiList dense>
        {log_references.list.map(renderReferenceListItem )}
      </MuiList>
      <Pagination totalPages={log_references.totalPages} page={page} onPageSelected={setPage}/>
    </PageContainer>
  )
}

export default (props) => (
  <LogReferencesContext>
    <List {...props} />
  </LogReferencesContext>
)