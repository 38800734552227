import React from 'react'
import FormHelperText from '@material-ui/core/FormHelperText'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'

// Meridian saves booleans as char 'Y' or 'N' (or empty string)
const MeridianBooleanField = (props) => {
  const {value, onChange, label, name, error, helperText, disabled, fullWidth, hasNone = false, ...other} = props

  return (
    <FormControl disabled={disabled} error={error} fullWidth={fullWidth}>
      <InputLabel shrink={value || (value === null)} style={{backgroundColor: '#fff', paddingLeft: 5, paddingRight: 5}}>{label}</InputLabel>
      <Select
        value={value}
        onChange={onChange}
        displayEmpty
        {...other}
      >
        {hasNone && <MenuItem value={null}>None</MenuItem>}
        <MenuItem value="Y">Yes</MenuItem>
        <MenuItem value="N">No</MenuItem>
      </Select>
      <FormHelperText error={error}>{helperText}</FormHelperText>
    </FormControl>
  )
}

export default MeridianBooleanField
