import React from "react";
import {AutocompleteSearch} from "components";
import {useAccessToken} from "hooks";
import * as API from 'api'

const AutocompleteUsers = ({ logBook, active, ...props }) => {
  const acquireAccessToken = useAccessToken()

  const onSuggestionsFetchRequested = async (text, callback) => {
    if (!logBook?.id) {
      callback([]);
    }

    const accessToken = await acquireAccessToken()
    const { data } = await API.LogBooksUsers.index(accessToken, {
      options: {
        fields: {logBooksUsers: null, users: "name"},
        filter: {search: text, logBook: logBook, activeFlag: active ? "Y" : undefined},
        page: { number: 1, size: 100 },
        include: 'user'
      }
    })
    callback(data.map(r => r.user))
  }

  return (
    <AutocompleteSearch fullWidth
                        onSuggestionsFetchRequested={onSuggestionsFetchRequested}
                        getOptionLabel={(opt) => opt?.name || ""}
                        {...props} />
  )
}

export default AutocompleteUsers