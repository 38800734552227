import { useState } from 'react'
import qs from 'qs'

const getUrlState = (namespace, attributes) => {
  const queryString = window.location.search.slice(1)
  const state = qs.parse(queryString, { allowDots: true })
  const namespaceState = namespace ? (state[namespace] || {}) : state
  if (attributes) {
    Object.keys(namespaceState).forEach(key => {
      if (!attributes.includes(key)) {
        delete namespaceState[key]
      }
    })
  }
  return namespaceState
}

const useQueryString = (namespace, attributes) => {
  const [urlState, _setUrlState] = useState(getUrlState(namespace, attributes))

  const setUrlState = (state) => {
    const updatedState = { ...urlState, ...state }
    const namespacedState = namespace ? { [namespace]: { ...getUrlState(namespace), ...updatedState } } : updatedState
    const combinedUrlState = { ...getUrlState(), ...namespacedState }
    const queryString = qs.stringify(combinedUrlState, { arrayFormat: 'repeat', allowDots: true })
    const location = [window.location.pathname, queryString].filter((i) => i).join('?')
    window.history.replaceState({}, '', location)
    _setUrlState(updatedState)
  }

  return [urlState, setUrlState]
}
export default useQueryString
