import { useState } from 'react'

const useFormData = (initial = {}, { defaults = {}, properties = {} } = {}) => {
  const [formState, setFormState] = useState({})
  const formData = {
    ...initial,
    ...formState,
  }
  Object.keys(defaults).forEach((key) => {
    if (!formData[key] || (Array.isArray(formData[key]) && formData[key].length === 0)) {
      formData[key] = defaults[key]
    }
  })
  Object.keys(properties).forEach((key) => {
    if (properties[key].get) {
      formData[key] = properties[key].get(formData)
    }
  })

  const setFormData = (data) => setFormState((prev) => {
    const next = { ...prev, ...data }
    const prevFromData = { ...formData, ...prev }
    Object.keys(properties).forEach((key) => {
      if (properties[key].set && prevFromData[key] !== data[key] && data.hasOwnProperty(key)) {
        Object.assign(next, properties[key].set(data[key]))
      }
    })
    return next
  })

  return [formData, setFormData]
}

export default useFormData
