import React, { Component } from 'react'
import PropTypes from 'prop-types'
import withStyles from 'styles'
import CircularProgress from '@material-ui/core/CircularProgress'

export class Loader extends Component {

  loaderStyle = () => {
    return {
      width: this.props.size,
      height: this.props.size
    }
  }

  render = () => <CircularProgress className={this.props.classes.loader} style={this.loaderStyle()} />
}

const styles = theme =>({
  loader: {
    position: "absolute",
    height: "50px",
    width: "100px",
    top: "50%",
    left: "50%",
    marginLeft: "-50px",
    marginTop: "-50px",
    backgroundSize: "100%",
  },
  '@keyframes load8':{
    '0%': {
      transform:"rotate(0deg)"
    },
    '100%': {
      transform:"rotate(360deg)"
    }
  }

})
export default withStyles(styles)(Loader)
