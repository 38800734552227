import React, {useEffect, Fragment, useContext, useState, useRef} from 'react'
import * as Containers from 'containers'
import { Routes, Nav } from '.'
import CssBaseline from '@material-ui/core/CssBaseline'
import { SnackbarContext, UsersContext, VersionsContext } from 'contexts'
import {makeStyles} from '@material-ui/core/styles'
import { useIsAuthenticated, useMsal } from "@azure/msal-react"
import {  LinkButton } from 'components'

const useStyles = makeStyles({
  contentWrapper: {
    flex: '1 0 auto',
    display: 'flex',
    '@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none)': {
      //IE ONLY
      minHeight: 'calc(100vh - 130px)'
    },
    '& > div': {
      flex: '1 0 auto',
      maxWidth: '100%'
    }
  },
  version: {
    position: 'absolute',
    bottom: 0,
    right: 10,
    fontSize: 11,
    color: "#9a9a9a"
  }
})

const CurrentRoutes = ({isAuthenticated, isLoaded}) => {

  if (isAuthenticated && !isLoaded) {
    return <Routes.Unknown/>
  } else if (isAuthenticated && isLoaded) {
    return <Routes.Authenticated/>
  } else {
    return <Routes.Unauthenticated/>
  }
}

const CurrentNav = ({isAuthenticated, isLoaded, loadFailed, logout}) => {
  if (loadFailed == true) {
    return (
          <div>
            <nav style={{textAlign: 'center'}}>
              <b>The ELog User Interface is unable to connect to the ELog API... you can either <LinkButton onClick={logout}>Logout</LinkButton> and try again or if this problem persists please contact the Service Desk </b>
            </nav>
          </div>)
  }

  if (isAuthenticated && !isLoaded) {
    return <Nav.Unknown/>
  } else if (isAuthenticated && isLoaded) {
    return <Nav.Authenticated/>
  } else {
    return <Nav.Unauthenticated/>
  }
}

const App = (props) => {

  const users = useContext(UsersContext.ReactContext)
  const versions = useContext(VersionsContext.ReactContext)

  const { instance } = useMsal();
  const handleLogout = () => {
    instance.logoutPopup({ postLogoutRedirectUri: "/", mainWindowRedirectUri: "/" })
      .catch((error) => console.log(error))
  }

  const containerRef = useRef()
  const isAuthenticated = useIsAuthenticated()
  const isLoaded        = users.currentUser?.email !== undefined

  const [loadFailed, setLoadFailed] = useState(null)
  const loadCurrentUser = async () => {
    try {
      await users.actions.current()
      setLoadFailed(false)
    } catch (error) {
      setLoadFailed(true)
    }
  }
  const loadTag = async () => {
    try {
      await versions.actions.getTag()
    } catch (error) {
      setLoadFailed(true)
    }
  }

  useEffect(() => {
    loadTag()
  }, [loadFailed])

  const classes = useStyles(props)

  if(isAuthenticated && !isLoaded) loadCurrentUser()

  return (
    <Fragment>
      <CssBaseline />
      <CurrentNav isAuthenticated={isAuthenticated} isLoaded={isLoaded} loadFailed={loadFailed} logout={handleLogout}  />
      <div className={classes.contentWrapper} ref={containerRef}>
      <CurrentRoutes isAuthenticated={isAuthenticated} isLoaded={isLoaded} />
      </div>
      {loadFailed == true &&
        <div className={classes.version}>
          API {versions.tag} / CLIENT {process.env.REACT_APP_VERSION}
        </div>
      }
      <Containers.Shared.Snackbar/>
    </Fragment>
  )
}

export default (props) => (
  <VersionsContext>
  <UsersContext>
    <SnackbarContext>
      <App {...props} />
    </SnackbarContext>
  </UsersContext>
  </VersionsContext>
)