import React, { Component } from 'react'
import Card from '@material-ui/core/Card';
import withStyles from 'styles'

export class CenteredCard extends Component{

  static defaultProps = {
    centerVertically: true
  }

  render = () =>
    <section className={this.props.classes('wrapper', {centerVertically: this.props.centerVertically})}>
      <Card className={this.props.classes.card}>
        {this.props.children}
      </Card>
    </section>

}

const styles = {
  wrapper: {
    justifyContent: 'center',
    display: 'flex',
    margin: '0 auto',
    alignSelf: 'flex-start'
  },
  centerVertically: {
    alignItems: 'center',
    minHeight: '100vh',
    flex: 1
  },
  card: {
    display: 'flex',
    flexDirection: 'column',
    flex: '0 0 350px'
  }
}

export default withStyles(styles)(CenteredCard)