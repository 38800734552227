import { useMsal } from "@azure/msal-react";
import { InteractionRequiredAuthError } from '@azure/msal-browser';

import {  MicrosoftService } from '../services'

const useAccessToken = () => {
  const { instance } = useMsal();

  const accounts = instance.getAllAccounts();
  const activeAccount = accounts[0]

  const acquireAccessToken = async () => {
    try {
      if (!activeAccount && accounts.length === 0) {
        throw new Error('User not signed in')
      }
      const authResult = await instance.acquireTokenSilent({scopes: MicrosoftService.login().scopes, account: activeAccount})
      return authResult.accessToken

    } catch (error) {
        if (InteractionRequiredAuthError.isInteractionRequiredError(error.errorCode)) {
          const response = await instance.acquireTokenPopup(MicrosoftService.login());
          return response.accessToken;
        }
    }
  }
  return acquireAccessToken
}
export default useAccessToken
