import React, {useContext} from 'react'
import {useDependency, useResourceFromPath} from 'hooks'
import { Link } from 'react-router-dom'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import Typography from '@material-ui/core/Typography'
import EditIcon from '@material-ui/icons/Edit'
import IconButton from '@material-ui/core/IconButton'
import { PageContainer } from 'components'
import { LogBooksContext } from 'contexts'
import BookmarkIcon from '@material-ui/icons//Bookmark';
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';

const Show = () => {
  const {id} = useResourceFromPath()
  const logBooks = useContext(LogBooksContext.ReactContext)

  useDependency(() => (
    logBooks.actions.show(id, {
      include: ''
    })
  ), [id])

  const logBook = logBooks.selected

  return (
    <PageContainer>
      <Typography variant='h4'>LogBook - {logBook.title}</Typography>
      <Table>
        <TableBody>
          <TableRow>
            <TableCell style={{width: 150}} variant='head'>Actions</TableCell>
            <TableCell>
              <Link to={`/log_books/${logBook.id}/edit`}>
                <IconButton>
                  <EditIcon/>
                </IconButton>
              </Link>
              <Link to={`/log_books_references/assigned_references/${logBook.id}`}>
                <IconButton>
                  <BookmarkIcon/>
                </IconButton>
              </Link>
              <Link to={`/log_books_instructions/assigned_instructions/${logBook.id}`}>
                <IconButton>
                  <ArrowRightAltIcon/>
                </IconButton>
              </Link>
            </TableCell>
          </TableRow>
            <TableRow>
              <TableCell variant='head'>Title</TableCell>
              <TableCell>{String(logBook.title || '')}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell variant='head'>Update Interval (minutes)</TableCell>
              <TableCell>{String(logBook.updateInterval || '')}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell variant='head'>Is active?</TableCell>
              <TableCell>{logBook.activeFlag === "Y" ? 'Yes' : 'No'}</TableCell>
            </TableRow>
        </TableBody>
      </Table>
      <br/>
    </PageContainer>
  )
}

export default (props) => (
  <LogBooksContext>
    <Show {...props} />
  </LogBooksContext>
)
