import React from "react";
import {AutocompleteSearch} from "components";
import {useAccessToken} from "hooks";
import * as API from 'api'

const AutocompleteReferences = ({ logBook, active, ...props }) => {
  const acquireAccessToken = useAccessToken()

  const onSuggestionsFetchRequested = async (text, callback) => {
    if (!logBook?.id) {
      callback([]);
    }
    const accessToken = await acquireAccessToken()
    const { data } = await API.LogBooksReferences.index(accessToken, {
      options: {
        fields: {logBooksReferences: "", logReferences: "name"},
        filter: {referenceName: text, logBookId: logBook?.id, activeFlag: active ? "Y" : undefined},
        page: { number: 1, size: 100 },
        include: 'logReference',
        sort: 'name'
      }
    })
    callback(data.map(r => r.logReference))
  }

  return (
    <AutocompleteSearch fullWidth
                        onSuggestionsFetchRequested={onSuggestionsFetchRequested}
                        getOptionLabel={(opt) => opt?.name || ""}
                        {...props} />
  )
}

export default AutocompleteReferences