import { Route, Switch, Redirect } from 'react-router-dom'
import * as Containers from 'containers'

const Routes = () => {
  return (
    <Switch>
      <Route exact path="/tokens/sign_in"             component={Containers.Token.SignIn}/>
      <Route component={() =>  <Redirect to={"/tokens/sign_in"}/>}/>
    </Switch>
  )
}

export default Routes
