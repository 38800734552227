// eslint-disable-next-line
export default classes => {
  const classFunc = function(...args){
    let res = args.map(arg => {
      if(typeof arg === 'string'){
        return classes[arg] || arg
      }
      else if(typeof arg === 'object'){
        return Object.entries(arg).map(([key, val]) => {
          return val !== false ? (classes[key] || key) : ''
        }).join(' ')
      }
      return []
    }).join(' ')
    return res
  }
  Object.keys(classes).forEach(key => classFunc[key] = classes[key])
  return classFunc
}