import React, {useContext} from 'react'
import { useFormData, useDependency, useInstanceSaver, useResourceFromPath } from 'hooks'
import {ControlledForm, ErrorBanner, PageContainer, PromiseButton} from 'components'
import Typography from '@material-ui/core/Typography'
import TextField from '@material-ui/core/TextField'

import { UsersContext } from 'contexts'
import {errorStringsFromError} from 'utils'

const Form = () => {
  const {id, action} = useResourceFromPath()
  const users = useContext(UsersContext.ReactContext)

  useDependency(() => {
    if(action === 'edit'){
      return users.actions.show(id)
    }
  }, [id, action])

  const [formData, setFormData] = useFormData(users.selected)
  const [save, errors] = useInstanceSaver(formData, users, action)

  return (
    <PageContainer>
      <Typography variant='h5'>{action === 'edit' ? `Edit User - ${formData.name}` : 'Create a new User'}</Typography>
      <ControlledForm data={formData} errors={errors} onChange={setFormData} onSubmit={save}>
        <ErrorBanner>{errorStringsFromError(users.actions.apiErrorsFor('create', 'update'))}</ErrorBanner>
        <TextField fullWidth name='name'/>
        <TextField fullWidth name='email'/>
        <TextField fullWidth name='networkId'/>
      <PromiseButton color='secondary' fullWidth variant='contained' type='submit'>Save</PromiseButton>
      </ControlledForm>
    </PageContainer>
  )
}

export default (props) => (
  <UsersContext>
    <Form {...props} />
  </UsersContext>
)