import BaseContext from './BaseContext'
import * as API from 'api'
import { capitalize } from 'utils'
import pluralize from 'pluralize'
import { uuid } from 'utils'
import { MsalContext } from "@azure/msal-react";
import { InteractionRequiredAuthError } from '@azure/msal-browser';

import {  MicrosoftService } from '../../services'

export default class APIContext extends BaseContext {

  static contextType = MsalContext


  static initialState = {
    ...this.initialState,
    requests: {},
    errors: {},
    selected:   {},
    list:       [],
    page:       1,
    totalPages: 1,
    count:      3
  }

  constructor(props){
    super(props)
    this.APIName = capitalize(pluralize(this.constructor.contextName))
    this.apiResource = API[this.APIName]
    if(!this.apiResource)
      throw new Error(`Required API Resource not found for ${this.constructor.contextName}. Looking for API.${this.APIName}`)
  }

  index = async({page = this.state.page, params={}, fields=null, include=null, filter=null, order=null, pageSize=undefined, ...opts }={}) => {
    const {data: list, meta: { totalPages }} = await this.performRequest('index')(
      {
        ...params,
        options: {
          page: { number: page, size: pageSize },
          ...order && {order},
          ...filter && {filter},
          ...fields && {fields},
          ...include && {include},
          ...opts
        }
      }
    )

    this.setState({list, page, totalPages})
    return list
  }

  create = async(item, {...options}={}) => {
    const { data:selected } = await this.performRequest('create')({...item, options})
    this.setState({selected, list: [...this.state.list, selected]})
    return selected
  }

  update = async(item, options={}) => {
    const { data: selected } = await this.performRequest('update')({...item, options})
    this.setState({selected, list: this.replace(this.state.list, selected)})
    return selected
  }

  destroy = async(record) => {
    await this.performRequest('destroy')(record)
    this.setState({list: this.remove(this.state.list, record)})
  }

  show = async(id, {fields=null, include=null }={}) => {
    const { data: selected } = await this.performRequest('show')({id, options: { ...fields && {fields}, ...include && {include} }})
    this.setState({selected, list: this.replace(this.state.list, selected)})
    return selected
  }

  remove = (collection, item) => {
    return collection.filter(i => i.id !== item.id)
  }

  replace = (collection, item) => {
    return collection.map(i => i.id === item.id ? {...i, ...item} : i)
  }

  request = async(name, accessToken, args) => {
    if(!this.apiResource[name]){
      throw new Error(`Endpoint "${name}" not defined on API.${this.APIName})}`)
    }

    return await this.apiResource[name](accessToken, ...args)
  }

  printMetaWarnings = (name, meta) => {
    try{
      if(meta.warnings.length){
        console.warn(`Warning on fetching ${this.contextName}:${name}`)
        meta.warnings.forEach(({message}) =>  console.warn(message))
      }
    }catch(err){}
  }

  apiErrorsFor = (...errorTypes) => {
    return errorTypes.reduce((errors, errorType) =>
      this.state.errors[errorType] ? [...errors, this.state.errors[errorType]] : errors
    , [])
  }

  accessToken = async () => {
      const accounts = this.context.accounts
      const instance = this.context.instance
      const activeAccount = instance.getActiveAccount()
    try {
      if (!activeAccount && accounts.length === 0) {
        throw new Error('User not signed in')
      }

      const authResult = await instance.acquireTokenSilent({scopes: MicrosoftService.login().scopes, account: activeAccount || accounts[0]})

      return authResult.accessToken
    } catch (error) {
        if (InteractionRequiredAuthError.isInteractionRequiredError(error.errorCode)) {
            const response = await instance.acquireTokenPopup(MicrosoftService.login());
            return response.accessToken;
        }
    }
  }

  performRequest = (name, clear=true) => async (...args) => {
    const requestProxy = { id: uuid() }
    const updatedState = {
      requests: {...this.state.requests, [name]: [...(this.state.requests[name] || []), requestProxy]},
      errors: {...this.state.errors}
    }
    if (clear) {
      this.setState(updatedState)
    }
    try {
      const token = await this.accessToken()
      const {data, meta} = await this.request(name, token, args)

      updatedState.errors[name] = null
      this.printMetaWarnings(name, meta)
      return {data, meta}
    } catch (err) {
      updatedState.errors[name] = err
      throw err
    } finally {
      if(updatedState.requests[name])
        updatedState.requests[name] = updatedState.requests[name].filter(request => request !== requestProxy)
      if(!updatedState.requests[name].length)
        updatedState.requests[name] = null

       if (clear) this.setState({errors: {...updatedState.errors}, requests: {...updatedState.requests}})
    }
  }
}
