import APIContext from 'contexts/base/APIContext'

export default class LogBooksInstructionsContext extends APIContext{
  static contextName = 'logBooksInstructions'
  
  set = async(item, {...options}={}) => {
    const { data } = await this.performRequest('set')({...item, options})
    this.setState({list: data})
    return data
    
  }
}
