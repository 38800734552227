import PersonIcon from '@material-ui/icons/Person'
import EmailIcon from '@material-ui/icons/Email'
import FaceIcon from '@material-ui/icons/Face'
import {PageContainer} from 'components'
import {Authorization} from 'utils'
import {makeStyles, Card, Typography, List, ListItem, ListItemIcon, ListItemText} from '@material-ui/core'


const useStyles = makeStyles(() => ({
  card: {
    marginTop: 20,
  }
}), {name: 'Profile'})

const Profile = (props) => {

  const currentUser = Authorization.user
  const classes = useStyles(props)

  return (
    <PageContainer>
      <Typography variant='h4'>Profile</Typography>
      <Card className={classes.card}>
        <List>
          <ListItem>
            <ListItemIcon><PersonIcon/></ListItemIcon>
            <ListItemText primary={currentUser.name} secondary='name'/>
          </ListItem>
          <ListItem>
            <ListItemIcon><EmailIcon/></ListItemIcon>
            <ListItemText primary={currentUser.email} secondary='email'/>
          </ListItem>
          <ListItem>
            <ListItemIcon><FaceIcon/></ListItemIcon>
            {currentUser.roles && currentUser.roles.length ? (<ListItemText primary={currentUser.roles.join(', ')} secondary='roles'/>)
             : <Typography variant='body1' >You do not have any assigned roles. You can contact the helpdesk to get them added.</Typography>
            }

          </ListItem>
        </List>
      </Card>
    </PageContainer>
  )
}

export default Profile