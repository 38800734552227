import {useContext} from 'react'
import {useHistory} from 'react-router-dom'
import {SnackbarContext} from 'contexts'
import {deepSet} from 'utils'

const useInstanceSaver = (
  formData, apiContext, action, {
    nested = [], linked = [], saveAction, afterSave, redirect, params = {}, mapFormData, errorsAction
  } = {}
) => {
  const history = useHistory()
  const snackbar = useContext(SnackbarContext.ReactContext)

  const createMode = action === 'new'
  const editMode = !createMode

  let attributesToSave = {...((typeof mapFormData === 'function') ? mapFormData(formData) : formData), relationships: {}}
  linked.forEach(attribute => {
    const relationship = attributesToSave[attribute]
    delete attributesToSave[attribute]
    if(relationship){
      if(Array.isArray(relationship)){
        attributesToSave.relationships[attribute] = {data: relationship.map(({type, id}) => ({ type, id }))}
      }else{
        attributesToSave.relationships[attribute] = {data: {type: relationship.type, id: relationship.id}}
      }
    }
  })
  nested.forEach(attribute => {
    const relationship = attributesToSave[attribute]
    delete attributesToSave[attribute]
    if(relationship)
      attributesToSave.relationships[attribute] = {data: relationship}
  })

  const actions = apiContext.actions
  const errors = apiContext.errors
  const defaultErrorsAction = editMode ? 'update' : 'create'
  const error = errors[errorsAction ?? defaultErrorsAction]
  const formErrors = Object.entries(error?.meta || {}).reduce((acc, [name, value]) => (
    deepSet(value.join(', '), name, acc)
  ), {})

  const save = async () => {
    try{
      let performSave
      if(!(saveAction)){
        performSave = editMode ?
          actions.update(attributesToSave, params) :
          actions.create(attributesToSave, params)
      } else {
        performSave = saveAction(attributesToSave, params)
      }
      const result = await performSave

      if(typeof afterSave === 'function') {
        afterSave(result)
      }

      if (redirect !== false) {
        let redirectTo = null
        if(redirect) {
          redirectTo = (typeof redirect === 'function') ? redirect(result) : redirectTo = redirect
        }

        if(redirectTo) {
          history.push(redirectTo)
        } else {
          // if(window.location.pathname !== Authorization.store.savedLocation){
          //   history.goBack()
          // }else{
          //   history.push('/')
          //   tokens.actions.clearSavedWindowLocation()
          // }
          history.goBack()
        }
      }

      snackbar && snackbar.actions.show(`Saved ${attributesToSave.name ? `"${attributesToSave.name}"` : '' }`)

      return result
    }catch(err){
      snackbar && snackbar.actions.show(`Error saving: ${attributesToSave.name ? `"${attributesToSave.name}"` : '' }`)
      console.error('Error saving. Rethrowing err: ', err)
      throw err
    }
  }

  return [save, formErrors]
}

export default useInstanceSaver
