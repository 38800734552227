/**
 * A basic queue structure that keeps track of in-flight requests and their relative ordering
 */
export default class RequestQueue {
  constructor(name){
    this.runningRequests            = []
    this.queuedRequests             = []
    this.sequence                   = 0
    this.processedSequence          = 0
    this.name                       = name
  }

  acquireSlot = () =>{
    const request = {
      type: this.name,
      sequence: this.sequenceNumber
    }
    this.runningRequests.push(request)
    return request
  }

  relinquishSlot = request => {
    const inOrder = request.sequence > this.processedSequence
    this.processedSequence = Math.max(this.processedSequence, request.sequence)
    this.runningRequests.splice(this.runningRequests.indexOf(request), 1)
    return inOrder
  }

  dequeue = () => {
    const nextRequest = this.queuedRequests.shift()
    nextRequest && nextRequest()
  }

  queue = request => {
    this.queuedRequests.push(request)
  }

  get sequenceNumber(){
    return this.sequence += 1
  }

  get length(){
    return this.runningRequests.length
  }
}