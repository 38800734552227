import {
  LogRecords as LogRecordsBase,
} from './generated'

export const LogRecords = LogRecordsBase.extend(
  '',
  {
    download: endpoint => endpoint.method('post').path(() => {
      return '/log_records/download'
    }).type('blob')
      .resultsHandler( (response) => {
        return {data: {blob: response.body}}
    }, {overwrite: true})
  },
)

export * from './generated'



